<template>
  <WeContainer card="" v-if="ready">
    <div class="row">
      <div class="col-12 col-lg-2 sticky-tabs">
        <Navigation v-bind:tabs="tabs" activeTab="category" />
      </div>
      <div class="col">
        <WeCard class="sticky-header shadow-sm">
          <div class="row align-items-center">
            <div class="col">
              <div class="sticky-header-content">
                <span>Kategori Detay Yönetimi</span>
              </div>
            </div>
            <div class="col-auto">
              <div class="d-flex">
                <div class="ml-auto">
                  <button class="btn btn-primary" v-on:click="addCategory">
                    <i class="fas fa-plus"></i
                    ><span class="ml-2">Kategori Ekle</span>
                  </button>
                </div>
                <WeSubmitButton
                  class="ml-3"
                  v-bind:submit-status="submit"
                  v-on:submit="saveBlocks"
                />
              </div>
            </div>
          </div>
        </WeCard>
        <WeCard class="shadow-sm draggable-content mt-3">
          <WeCard class="alert-info" v-if="!categories.length">
            <i class="fas fa-info-circle fa-lg"></i>
            <b class="ml-2">Lütfen Kategori Ekleyin</b>
          </WeCard>
          <div
            v-for="(element, index) in categories"
            :key="index"
            class="draggable-block mb-2"
          >
            <div class="row align-items-center">
              <div class="col">
                <span class="font-weight-bold">{{ element.title }}</span>
              </div>
              <div class="col-auto">
                <div class="d-flex align-items-center">
                  <WeSwitch
                    v-if="!element.is_required"
                    v-bind:show-status="true"
                    v-bind:value="element.is_active"
                    v-on:change="changeBlockActive(index)"
                  />
                  <a
                    v-if="element.id"
                    v-bind:href="'/showcase-management/category/' + element.id"
                    class="btn btn-light ml-3 edit-showcase"
                  >
                    <i class="fa fa-edit mr-2" />
                  </a>
                  <a
                    class="btn ml-3 draggable-trash"
                    v-if="!element.is_required"
                    v-on:click="removeBlock(index)"
                  >
                    <i class="fa fa-trash mr-2" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </WeCard>
      </div>
    </div>
    <WeModal
      modalWidth="30"
      title="Kategori Ekle"
      class="categoryModal"
      v-if="categoryModal"
      v-on:close="closeModal"
    >
      <div slot="body">
        <WeCategory
          v-bind:current-category="$route.params.id"
          v-bind:multiple="false"
          v-bind:draggable="true"
          v-bind:showSelectedCategories="false"
          v-bind:placeholder="$t('searchCategory')"
          ref="categorySelector"
        />
        <button
          class="btn btn-primary ml-auto d-block mt-4"
          v-on:click="addCategoryToBlock"
        >
          <i class="fa fa-plus mr-2" />
          <span>Ekle</span>
        </button>
      </div>
    </WeModal>
  </WeContainer>
  <WeLoading v-else />
</template>
<style lang="scss" scoped>
.categoryModal .custom-card-title {
  display: none;
}
.categoryModal .card {
  border: none;
}
.categoryModal .modal-body {
  padding: 0;
}
.categoryModal .card-body {
  padding: 0;
}
.sticky-tabs {
  position: sticky;
  top: -2px;
  height: max-content;
  margin-bottom: -16px;
}
.sticky-header {
  top: -2px;
  position: sticky;
  z-index: 10;
}
.sticky-header-content {
  display: flex;
  align-items: center;
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
}
.sticky-header-content > span {
  font-size: 17px;
  font-weight: 600;
}
.draggable-block {
  border: 1px solid #dfdfdf;
  border-radius: 4px;
  padding: 12px;
  padding-left: 24px;
  padding-right: 24px;
}
.draggable-block:hover {
  background-color: #fbfbfb;
}
.draggable-block:hover .name-edit {
  display: block;
}
.draggable-block > i {
  color: #3f51b5;
  margin-right: 24px;
  font-size: 16px;
}

.draggable-block > input {
  font-size: 15px;
  font-weight: 600;
  min-width: 20ch !important;
  border: none;
  background: transparent;
  max-width: 700px;
}
.draggable-block a i {
  margin: 0 !important;
}

.draggable-block .custom-switch {
  margin-right: 16px;
}

.draggable-block > *:nth-child(4) {
  margin-left: auto !important;
}

.draggable-trash {
  background: #fef5f7;
  color: #dc3545;
}

.draggable-trash:hover {
  background: #dc3545;
  color: #fff;
}
</style>
<script>
import Navigation from "../../../Navigation/Index";
import draggable from "vuedraggable";
import { mapActions } from "vuex";

export default {
  name: "Blocks",
  data() {
    return {
      reactiveWidth: 100,
      categories: [],
      tabs: [],
      ready: false,
      submit: false,
      categoryModal: false,
    };
  },
  components: {
    Navigation,
    draggable,
  },
  methods: {
    ...mapActions("showcaseManagement", [
      "getHomeBlocks",
      "getCategoryBlocks",
      "setCategoryBlocks",
    ]),
    removeBlock(index) {
      this.$swal({
        title: "Bu vitrini kaldırmak istediğinizden emin misiniz?",
        showCancelButton: true,
        cancelButtonText: "İptal",
        confirmButtonText: "Sil",
        icon: "warning",
      }).then((confirm) => {
        if (confirm.isConfirmed) {
          this.categories.splice(index, 1);
        }
      });
    },
    addBlock() {
      this.categories.push({
        name: "",
        type: "block",
        is_active: true,
      });
    },
    addCategory() {
      this.categoryModal = true;
    },
    saveBlocks() {
      function isBlank(str) {
        return !str || /^\s*$/.test(str);
      }
      let request = [];
      let blankStatus = false;
      this.categories.forEach((tab, index) => {
        if (isBlank(tab.title)) {
          this.$toast.error("Lütfen Blok İsmi Değerlerini Giriniz...");
          blankStatus = true;
          return false;
        }
        var requestObject = {
          title: tab.title,
          index: index,
          is_active: Number(tab.is_active),
          type: tab.type,
        };

        if (tab.id) {
          requestObject.id = tab.id;
        }

        if (tab.is_required) {
          requestObject.is_required = tab.is_required;
        }
        if (tab.category_id) {
          requestObject.category_id = tab.category_id;
        }

        request.push(requestObject);
      });
      if (!blankStatus) {
        this.submit = true;
        request = request.sort((a, b) => a.index - b.index);
        this.setCategoryBlocks({
          request,
          onSuccess: (result) => {
            if (result && result.data && result.data.status !== "error") {
              this.$toast.success("Vitrin Kaydedildi");
              setTimeout(() => {
                window.location.reload();
              }, 500);
            } else {
              this.$toast.error("Vitrin Kaydedilemedi");
            }
          },
          onFinish: () => {
            this.submit = false;
          },
          onError: (error) => {
            this.$toast.error("Vitrin Kaydedilemedi");
          },
        });
      }
    },
    closeModal() {
      this.categoryModal = false;
    },
    changeBlockActive(index) {
      this.categories[index].is_active = !this.categories[index].is_active;
    },
    addCategoryToBlock() {
      let name = this.$refs.categorySelector.selected[0].name;
      if (name && name != "") {
        this.categoryModal = false;
        this.categories.push({
          title: name,
          type: "category",
          is_active: true,
          category_id: this.$refs.categorySelector.selected[0].id,
        });
      }
    },
  },
  mounted() {
    this.getHomeBlocks({
      onSuccess: (result) => {
        this.tabs = result?.data.items;
        this.getCategoryBlocks({
          onSuccess: (result) => {
            this.categories = result?.data.items;
          },
          onFinish: () => {
            this.ready = true;
          },
        });
      },
    });
  },
};
</script>
